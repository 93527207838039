/*!

=========================================================
* Light Bootstrap Dashboard PRO React - v1.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/light-bootstrap-dashboard-pro-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React, { Component } from "react";
import { Grid, Row, Col, FormGroup, ControlLabel, Form } from "react-bootstrap";

import Card from "components/Card/Card.jsx";
import Button from "components/CustomButton/CustomButton.jsx";
import SweetAlert from "react-bootstrap-sweetalert";

import * as accountService from "../../services/accountService";
import { withRouter } from "react-router";

class LoginPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      alert: null,
      cardHidden: true,
    };

    this.userName = React.createRef();
    this.password = React.createRef();
  }

  componentDidMount() {
    setTimeout(
      function () {
        this.setState({ cardHidden: false });
      }.bind(this),
      700
    );
  }

  handleSubmit = async () => {
    try {
      const data = {
        username: this.userName.current.value,
        password: this.password.current.value,
      };

      const res = await accountService.signin(data);
      if (res.status === 200) {
        const code = res.data.code;
        if (code !== "0") {
          this.basicAlert(res.data.message);
        } else {
          if (res.data.result.role !== "Dashboard") {
            this.basicAlert("Not Authorized User");
          } else {
            localStorage.setItem("token", res.data.result.token);
            this.props.history.push("/admin/enrollCount");
          }
        }
      }
    } catch (ex) {
      if (ex.response && ex.response.status === 401) {
        alert(ex.response.status);
      }
    }
  };

  basicAlert = (msg) => {
    this.setState({
      alert: (
        <SweetAlert
          danger
          style={{ display: "block", marginTop: "-100px" }}
          title={msg}
          onConfirm={this.hideAlert}
          confirmBtnBsStyle="danger"
        />
      ),
    });
  };

  hideAlert = () => {
    this.setState(
      {
        alert: null,
      },
      this.props.history.push("/auth/login-page")
    );
  };

  render() {
    return (
      <Grid>
        {this.state.alert}
        <Row>
          <Col md={4} sm={6} mdOffset={4} smOffset={3}>
            <Form onSubmit={this.handleSubmit}>
              <Card
                hidden={this.state.cardHidden}
                textCenter
                title="Login"
                content={
                  <div>
                    <FormGroup>
                      <ControlLabel>User Name</ControlLabel>
                      <input
                        className="form-control"
                        type="text"
                        ref={this.userName}
                        placeholder="Enter User Name"
                        //defaultValue="aeon_dashboard"
                      ></input>
                    </FormGroup>
                    <FormGroup>
                      <ControlLabel>Password</ControlLabel>
                      <input
                        className="form-control"
                        type="password"
                        ref={this.password}
                        placeholder="Enter Password"
                        //defaultValue="bot software"
                      ></input>
                    </FormGroup>
                  </div>
                }
                legend={
                  <Button type="submit" bsStyle="info" fill wd>
                    Login
                  </Button>
                }
                ftTextCenter
              />
            </Form>
          </Col>
        </Row>
      </Grid>
    );
  }
}

export default withRouter(LoginPage);
