import http from "./httpService";
import { apiUrl } from "../config.json";

const apiEndpoint = apiUrl + "/account/signin";
const tokenKey = "token";

export async function signin(authData) {
  return await http.post(apiEndpoint, authData);
}

export async function signout() {
  const token = localStorage.getItem(tokenKey);

  return await http.post(apiUrl + "/account/signout", null, {
    headers: {
      "X-Access-Token": token,
    },
  });
}

export function getToken() {
  return localStorage.getItem(tokenKey);
}

export default {
  signin,
};
