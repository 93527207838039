import http from "./httpService";
import { apiUrl } from "../config.json";

export async function enrollCount(searchForm) {
  const apiEndPoint = apiUrl + "/reports/member/enrollcount";
  return await http.post(apiEndPoint, searchForm, {
    headers: {
      "X-Access-Token": localStorage.getItem("token"),
    },
  });
}

export async function bizCodeCount(searchForm) {
  const apiEndPoint = apiUrl + "/reports/doortxn/bizcodecount";
  return await http.post(apiEndPoint, searchForm, {
    headers: {
      "X-Access-Token": localStorage.getItem("token"),
    },
  });
}

export async function memberDoorTxnCount(searchForm) {
  const apiEndPoint = apiUrl + "/reports/doortxn/memberDoorTxnCount";
  return await http.post(apiEndPoint, searchForm, {
    headers: {
      "X-Access-Token": localStorage.getItem("token"),
    },
  });
}
