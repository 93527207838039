import React, { Component } from "react";

import Card from "components/Card/Card.jsx";
import {
  Grid,
  Row,
  Col,
  Table,
  ControlLabel,
  FormGroup,
  Form,
} from "react-bootstrap";
import Button from "components/CustomButton/CustomButton.jsx";
import Pagination from "react-js-pagination";
import DateTime from "react-datetime";
import Select from "react-select";
import SweetAlert from "react-bootstrap-sweetalert";
import { CSVLink } from "react-csv";
import moment from "moment";

import * as accountService from "../../services/accountService";
import * as reportService from "../../services/reportService";

class EnrollCount extends Component {
  constructor(props) {
    super(props);
    this.state = {
      alert: null,
      csvData: "",
      enrollData: { count: 0, items: [], summary: [] },
      currentPage: 1,
      selectedOption: { value: 10, label: "10 Days" },
      pageSizes: [
        { value: -1, label: "All" },
        { value: 10, label: "10 Days" },
        { value: 20, label: "20 Days" },
        { value: 30, label: "30 Days" },
      ],
      dateRange: {
        title: "Search",
        displayFormat: "DD/MM/YYYY",
        apiFormat: "YYYY-MM-DD HH:mm:ss",
        fromDateFormat: "YYYY-MM-DD 00:00:00",
        toDateFormat: "YYYY-MM-DD 23:59:59",
        fromDate: moment().subtract(30, "days"),
        toDate: moment(),
      },
      searchForm: {
        offset: 0,
        page_size: 0,
        is_or: true,
        date_filter: {},
        filters: [],
        orders: [
          {
            name: "created",
            reverse: false,
          },
        ],
      },
      table: {
        labels: ["#", "Date", "Member", "Guest"],
        body: [],
      },
    };
  }

  hideAlert = () => {
    this.setState(
      {
        alert: null,
      },
      this.props.history.push("/auth/login-page")
    );
  };

  basicAlert = (msg) => {
    this.setState({
      alert: (
        <SweetAlert
          warning
          style={{ display: "block", marginTop: "-100px" }}
          title={msg}
          onConfirm={this.hideAlert}
          confirmBtnBsStyle="warning"
        />
      ),
    });
  };

  search = async () => {
    if (accountService.getToken() === null) {
      this.basicAlert("Token Not Found");
    } else {
      const { dateRange } = { ...this.state };
      const searchForm = this.state.searchForm;

      searchForm.date_filter = {
        name: "created",
        start: dateRange.fromDate.format(dateRange.fromDateFormat),
        end: dateRange.toDate.format(dateRange.toDateFormat),
      };
      searchForm.offset = this.state.currentPage - 1;

      const { value: pageSize } = { ...this.state.selectedOption };
      searchForm.page_size = pageSize;

      this.setState({ searchForm });

      try {
        const res = await reportService.enrollCount(searchForm);
        const csv = res.data.result.csv.join("\n");
        this.setState({ csvData: csv });
        this.setState({ enrollData: res.data.result });
      } catch (ex) {
        if (ex.response && ex.response.status === 401) {
          this.basicAlert("Session Timeout");
        }
      }
    }
  };

  componentDidMount() {
    this.search();
  }

  handleSubmit = (e) => {
    e.preventDefault();

    this.setState({ currentPage: 1 });

    const { searchForm } = { ...this.state };
    searchForm.offset = 0;

    this.setState(searchForm, () => this.search());
  };

  handleFromDateChange = (e) => {
    const { dateRange } = { ...this.state };
    dateRange.fromDate = e;

    this.setState({ dateRange });
  };

  handleToDateChange = (e) => {
    const { dateRange } = { ...this.state };
    dateRange.toDate = e;

    this.setState({ dateRange });
  };

  renderTableHeader = () => {
    const labels = this.state.table.labels;

    return (
      <tr>
        {labels.map((label, index) => (
          <th key={index}>{label}</th>
        ))}
      </tr>
    );
  };

  renderTableBody = () => {
    const { items } = { ...this.state.enrollData };
    if (items === undefined) return null;
    const { value } = { ...this.state.selectedOption };
    const pageSize = value === -1 ? 1 : value;

    return items.map((item, index) => {
      return (
        <tr key={index}>
          <td>{this.state.searchForm.offset * pageSize + (index + 1)}</td>
          <td>
            {moment(item.date).format(this.state.dateRange.displayFormat)}
          </td>
          <td>{item.member}</td>
          <td>{item.guest}</td>
        </tr>
      );
    });
  };

  getDateRange = () => {
    const { fromDate, toDate, displayFormat } = {
      ...this.state.dateRange,
    };
    return (
      <Form horizontal onSubmit={this.handleSubmit}>
        <FormGroup>
          <ControlLabel className="col-md-1">From Date</ControlLabel>
          <Col md={2}>
            <DateTime
              id="enrollFromDate"
              name="enrollFromDate"
              dateFormat={displayFormat}
              timeFormat={false}
              defaultValue={fromDate}
              onChange={this.handleFromDateChange}
              closeOnSelect={true}
              isValidDate={(current) =>
                !current.isAfter(this.state.dateRange.toDate)
              }
              inputProps={{ readOnly: true }}
            />
          </Col>
          <ControlLabel className="col-md-1">To Date</ControlLabel>
          <Col md={2}>
            <DateTime
              id="enrollToDate"
              name="enrollToDate"
              dateFormat={displayFormat}
              timeFormat={false}
              defaultValue={toDate}
              onChange={this.handleToDateChange}
              closeOnSelect={true}
              isValidDate={(current) =>
                current.diff(this.state.dateRange.fromDate, "days") >= 0
              }
              inputProps={{ readOnly: true }}
            ></DateTime>
          </Col>
          <ControlLabel className="col-md-1"></ControlLabel>
          <Col md={2} mdPull={1}>
            <Button type="submit" bsStyle="info" fill>
              Search
            </Button>
          </Col>
        </FormGroup>
      </Form>
    );
  };

  getPageSize = () => {
    return (
      <Select
        options={this.state.pageSizes}
        defaultValue={{ value: -1, label: "All" }}
        value={this.state.selectedOption}
        onChange={(value) => {
          this.setState({ currentPage: 1 });
          this.setState({ selectedOption: value }, () => {
            this.search();
          });
        }}
      />
    );
  };

  handlePageChange = (page) => {
    this.setState({ currentPage: page }, () => {
      this.search();
    });
  };

  render() {
    const dateRange = this.getDateRange();
    const selectPageSize = this.getPageSize();
    const { value } = { ...this.state.selectedOption };
    const pageSize = value === -1 ? this.state.enrollData.count : value;

    return (
      <div className="main-content">
        {this.state.alert}
        <Grid fluid>
          <Row>
            <Col md={12}>
              <Card title={<legend>Search</legend>} content={dateRange}></Card>
            </Col>
          </Row>
          <Row>
            <Col md={12}>
              <Card
                title={<legend>Result</legend>}
                tableFullWidth
                content={
                  <React.Fragment>
                    <Row style={{ paddingLeft: "15px", paddingRight: "15px" }}>
                      <Col md={1}>
                        {this.state.csvData &&
                          (() => (
                            <CSVLink
                              data={this.state.csvData}
                              className="btn btn-fill btn-success"
                              filename={`${moment().format(
                                "YYYYMMDD_HHmmSS"
                              )}_enrollCount.csv`}
                            >
                              Download
                            </CSVLink>
                          ))()}
                      </Col>
                      <Col md={9}></Col>
                      <Col md={2}>{selectPageSize}</Col>
                    </Row>
                    <Table striped hover responsive>
                      <thead>{this.renderTableHeader()}</thead>
                      <tbody>{this.renderTableBody()}</tbody>
                    </Table>
                    <Row style={{ paddingLeft: "15px", paddingRight: "15px" }}>
                      <Col md={12}>
                        {
                          <Pagination
                            activePage={this.state.currentPage}
                            itemsCountPerPage={pageSize}
                            totalItemsCount={this.state.enrollData.count}
                            pageRangeDisplayed={5}
                            onChange={this.handlePageChange.bind(this)}
                          />
                        }
                      </Col>
                    </Row>
                  </React.Fragment>
                }
              ></Card>
            </Col>
          </Row>
        </Grid>
      </div>
    );
  }
}

export default EnrollCount;
